
import {
  defineAsyncComponent,
  defineComponent,
  PropType,
  SetupContext,
} from 'vue';

/** Interfaces */
import { RepositoryObligationI } from '@/interfaces/general.interface';

/** Composables */
import { usePaymentCard } from '@/composition/usePaymentCard';
import { useUtilities } from '@/composition/useUtilities';
import { ObligationTypes } from '@/utils/multi-pdf-types';

export default defineComponent({
  name: 'DannRegionalCard',
  components: {
    Tooltips: defineAsyncComponent(
      () => import('@/components/shared/Tooltips.vue')
    ),
    Phone: defineAsyncComponent(
      () => import('@/components/shared/icons/Phone.vue')
    ),
    Loader: defineAsyncComponent(
      () => import('@/components/loader/Loader.vue')
    ),
  },
  props: {
    repositoryObligations: {
      type: Array as PropType<RepositoryObligationI[]>,
      required: true,
    },
    isLoadingPaymentStatus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['view-detail-plan', 'make-payment'],
  setup(_props, context: SetupContext) {
    const obligationType = ObligationTypes.AVAL_REPOSITORY;
    const { validatePaymentDate } = useUtilities();

    const useClickToCall = () => {
      const url = 'https://customers.wolkvox.com/clicktocall/referencia';
      window.open(url, '_blank', 'noreferrer');
    };

    const {
      bindClassState,
      viewDetailPlan,
      makePayment,
      valueGreaterThanZero,
      getReference,
      canMakePayment,
      getLastPaymentStatus,
    } = usePaymentCard(context);

    return {
      bindClassState,
      viewDetailPlan,
      makePayment,
      validatePaymentDate,
      obligationType,
      valueGreaterThanZero,
      useClickToCall,
      getReference,
      canMakePayment,
      getLastPaymentStatus,
    };
  },
});
